import { useQuery } from '@tanstack/react-query'
import { request, gql } from 'graphql-request'
import { CHAIN_SUBGRAPH_URL } from '../constants/chainInfo'

const useEnvironments = () => {
  return useQuery(['useEnvironments'], () => fetchAllEnvironments())
}

const fetchEnvironments = async chainId => {
  let environments = await request(
    CHAIN_SUBGRAPH_URL[chainId],
    gql`
      query Environments {
        environments {
          id
          namespace
        }
      }
    `
  )
  return environments.environments
}

const fetchAllEnvironments = async id => {
  let allEnvironments = []
  for (const chainId in CHAIN_SUBGRAPH_URL) {
    const newEnvironments = await fetchEnvironments(chainId)
    newEnvironments.forEach(newEnv => {
      
      const existingEnvIndex = allEnvironments.findIndex(
        env => env.id === newEnv.id
      )

      if (existingEnvIndex !== -1) {
        allEnvironments[existingEnvIndex].networks.push(chainId)
      } else {
        allEnvironments.push({ ...newEnv, networks: [chainId] })
      }
    })
  }


  return allEnvironments
}

export default useEnvironments
