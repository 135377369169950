import React from 'react'
import Address from '../components/Address'
import LongId from '../components/LongId'
import Network from '../components/Network'
import Token from '../components/Token'
import TokenAmount from '../components/TokenAmount'
import Table from '../structure/Table/Table'
import TableRow from '../structure/Table/TableRow'
import TableHeader from '../structure/Table/TableHeader'
import TableCell from '../structure/Table/TableCell'
import { capitalizeFirstLetter } from '../utils/general-utils'
import styled from 'styled-components'

const ParamTable = ({ data }) => {
  console.log('settings', data)
  return (
    <CustomTable
      header={
        <TableRow>
          {Object.keys(data[0]).map(
            item =>
              data[0][item] && (
                <TableHeader
                  key={item}
                  title={capitalizeFirstLetter(
                    item.replace(/([A-Z]+)*([A-Z][a-z])/g, '$1 $2')
                  )}
                  align="left"
                />
              )
          )}
        </TableRow>
      }
    >
      {data.map((value, i) => (
        <TableRow key={i}>
          {Object.entries(value).map(
            ([key, item]) =>
              item && (
                <TableCell key={key}>
                  <Item data={item.toString()} item={item} type={key} value={value} />
                </TableCell>
              )
          )}
        </TableRow>
      ))}
    </CustomTable>
  )
}
const CustomTable = styled(Table)`
  th {
    white-space: normal;
    vertical-align: top;
    display: table-cell;
  }
`

const Item = ({ data, type = 'string', value = '', item }) => {

  if (type === 'address' || type === 'signer' || type === 'recipient' ) {
    return <Address address={data} chainId={value?.network} />
  }
  if (type === 'feeCollector') {
    return <Address address={data} short={true} />
  }
  if (type === 'network') {
    return <Network network={data} />
  }

  if (type === 'token') {
    return <Token address={data} open={true}/>
  }
  if (type === 'maxAmount' || type === 'minAmount') {
    return <TokenAmount tokenAmount={item} />
  }
  if (type === 'nextBalanceConnector' || type === 'previousBalanceConnector') {
    return <LongId longId={data} />
  }
  return <>{data}</>
}

export default ParamTable
