import { useQuery } from '@tanstack/react-query'
import { request, gql } from 'graphql-request'
import { CHAIN_SUBGRAPH_URL } from '../constants/chainInfo'

const useNamespace = id => {
  const data = useQuery(['useNamespace', id], () => fetchAllNamespace(id))
  return data
}

const fetchNamespace = async (chainId, id) => {
  let data = await request(
    CHAIN_SUBGRAPH_URL[chainId],
    gql`
    query Namespace {
      environment(id: ${'"' + id.toLowerCase() + '"'}) {
        network
        namespace
        id
      }
    }
    `
  )
  return data?.environment?.namespace
}

const fetchAllNamespace = async id => {
  let data = ''
  for (const chainId in CHAIN_SUBGRAPH_URL) {
    const namespace = await fetchNamespace(chainId, id)
    if (namespace) {
      data = namespace
      break
    }
  }

  return data
}

export default useNamespace
