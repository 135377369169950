import { useQuery, useQueryClient } from '@tanstack/react-query'
import { request, gql } from 'graphql-request'
import { CHAIN_SUBGRAPH_URL } from '../constants/chainInfo'

const usePermisions = id => {
  const queryClient = useQueryClient()

  const { data, isLoading, isError } = useQuery(
    ['usePermisions', id],
    () => fetchAllPermissions(id.toString()),
    {
      invalidateOnMount: true,
    }
  )

  const refetch = () => {
    queryClient.invalidateQueries(['usePermisions', id])
  }

  return {
    data,
    isLoading,
    isError,
    refetch,
  }
}







const fetchPermissions = async (chainId, id) => {
  let permision = await request(
    CHAIN_SUBGRAPH_URL[chainId],
    gql`
    query Permissions {
      permissions(where: {where: ${'"' + id.toLowerCase() + '"'}}) {
        id
        who
        what
        method
      }
    }
    `
  )
  return permision?.permissions.map(item => {
    return { ...item, chainId: chainId }
  }) || ''
}

const fetchAllPermissions = async id => {
  const allPermissions = []
  for (const chainId in CHAIN_SUBGRAPH_URL) {
    const permissions = await fetchPermissions(chainId, id)
    allPermissions.push(...permissions)
  }

  return allPermissions
}

export default usePermisions
