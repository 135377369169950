import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { Container } from '../styles/texts'
import TableRow from '../structure/Table/TableRow'
import TableHeader from '../structure/Table/TableHeader'
import Breadcrumbs from '../structure/Breadcrumbs'
import Network from '../components/Network'
import EmptyState from '../components/EmptyState'
import LogsItem from '../sections/LogsItem'
import { Heading1 } from '../styles/texts'
import { Skeleton, ContainerTable } from '../styles/general'
import useLogs from '../hooks/useLogs'
import arrowDown from '../assets/arrow-down.svg'
import { CHAIN_INFO, SupportedChainId } from '../constants/chainInfo'
import { EnvironmentsDictionary } from '../constants/environmentsDictionary'

const Logs = ({ width }) => {
  const params = useParams()
  const buttonRef = useRef(null)
  const defaultChain = EnvironmentsDictionary[params.id]?.defaultChainId || 1
  const [selectedNetwork, setSelectedNetwork] = useState(defaultChain)
  const [selectedStatus, setSelectedStatus] = useState('')
  const [selectedToken, setSelectedToken] = useState('')
  const [selectedColored, setSelectedColored] = useState(false)
  const [selectedPlanId, setSelectedPlanId] = useState('')
  const [intervalMs, setIntervalMs] = React.useState(false)
  const [filters, setFilters] = useState({})
  const [page, setPage] = useState(1)
  const { data, isLoading, isRefetching } = useLogs(params.id, page, 70, filters, intervalMs ? 5000 : 0)
  const navigate = useNavigate()
  const location = useLocation()

  const updateURL = (newFilters) => {
    const searchParams = new URLSearchParams()
    // Add filter parameters to the URL search params
    Object.keys(newFilters).forEach(key => {
      if (newFilters[key]) {
        searchParams.set(key, newFilters[key])
      } else {
        // Remove the parameter if the value is empty
        searchParams.delete(key)
      }
    })
    // For React Router v6
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true })
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const token = searchParams.get('token') || ''
    const chainId = searchParams.get('chainId') || selectedNetwork
    const status = searchParams.get('status') || ''
    const realtime = searchParams.get('realtime') || ''
    const colored = searchParams.get('colored') || ''
    const executionPlanId = searchParams.get('executionPlanId') || ''

    setSelectedNetwork(chainId)
    setSelectedToken(token)
    setSelectedStatus(status)
    setFilters({ token, status, executionPlanId, chainId })
    setSelectedColored(colored)
    setIntervalMs(realtime)
    setSelectedPlanId(executionPlanId)
    // Call API or other actions needed with the filters
    updateURL({ token, status, executionPlanId, chainId })
    // eslint-disable-next-line
  }, []) // Empty dependency array to run only on component mount

  const handlePageChange = (newPage) => {
    setPage(newPage)
  }

  const handleImageClick = () => {
    buttonRef.current.dispatchEvent(new Event('click', { bubbles: true }))
  }

  const handleSelectChange = (e) => {
    setSelectedNetwork(e.target.value)
    setPage(1)
    if (e.target.value !== '') {
      setFilters({ ...filters, chainId: e.target.value })
    } else {
      const { chainId, ...otherFilters } = filters
      setFilters({ ...otherFilters })
    }
  }

  const handleRealTimeChange = (e) => {
    setIntervalMs(!intervalMs)
    updateURL({ ...filters, colored: selectedColored, realtime: !intervalMs })

  }

  const handleColored = (e) => {
    setSelectedColored(!selectedColored)
    updateURL({ ...filters, realtime: intervalMs, colored: !selectedColored })
  }

  const handleSelectStatus = (e) => {

    setSelectedStatus(e.target.value)
    setPage(1)
    if (e.target.value !== '') {
      setFilters({ ...filters, status: e.target.value })
      updateURL({ ...filters, status: e.target.value })
    } else {
      const { status, ...otherFilters } = filters
      setFilters({ ...otherFilters })
      updateURL({ ...otherFilters })
    }
  }

  const handleSelectToken = (e) => {
    const newTokenValue = e.target.value
    setSelectedToken(newTokenValue)
    setPage(1)

    if (newTokenValue !== '') {
      setFilters({ ...filters, token: newTokenValue })
      updateURL({ ...filters, token: newTokenValue })
    } else {
      // Remove the token filter when the value is empty
      const { token, ...otherFilters } = filters
      setFilters({ ...otherFilters })
      updateURL({ ...otherFilters })
    }
  }

  const handleSelectPlanId = (newPlanId) => {
    setSelectedPlanId(newPlanId)
    setPage(1)
    setTimeout(() => {
      if (newPlanId !== '') {
        setFilters({ ...filters, executionPlanId: newPlanId })
        updateURL({ ...filters, executionPlanId: newPlanId })
      } else {
        // Remove the token filter when the value is empty
        const { executionPlanId, ...otherFilters } = filters
        setFilters({ ...otherFilters })
        updateURL({ ...otherFilters })
      }
    }, 500)
  }

  const handleReset = () => {
    setSelectedToken('')
    setSelectedPlanId('')
    setPage(1)
    const { executionPlanId, ...otherFilters } = filters
    setFilters({ ...otherFilters })
    updateURL({ ...otherFilters })
  }

  return (
    <ActivitySection>
      <Container>
        <Breadcrumbs />
        <Heading1>Logs</Heading1>
        <Flex>

          <div className="custom-select-container">
            <select
              value={selectedNetwork}
              onChange={handleSelectChange}
              className="custom-select"
              ref={buttonRef}
            >
              <option value="">All Networks</option>
              {Object.values(SupportedChainId).map((chainId) => (
                <option key={chainId} value={chainId}>
                  <Network network={CHAIN_INFO[chainId].name} noLogo={true} />
                </option>
              ))}
            </select>
            <div id="arrow-container" className="arrow-container" onClick={handleImageClick}>
              <img src={arrowDown} alt="Arrow" />
            </div>
          </div>
          <div className="custom-select-container">
            <select
              value={selectedStatus}
              onChange={handleSelectStatus}
              className="custom-select"
            >
              <option value="">All Status</option>
              <option value="notSimulated">Not Simulated</option>
              <option value="simulatedOk">Simulated OK</option>
              <option value="simulatedFail">Simulated Fail</option>
              <option value="reverted">Fail</option>
              <option value="success">Success</option>
            </select>
            <div id="arrow-container" className="arrow-container" onClick={handleImageClick}>
              <img src={arrowDown} alt="Arrow" />
            </div>
          </div>
          <div className="custom-select-container">
            <input
              value={selectedToken}
              placeholder='Token Address'
              onChange={handleSelectToken}
              className="custom-select"
            />
          </div>
          <div className="custom-plan-container">
            <input
              value={selectedPlanId}
              placeholder='Plan Id'
              onChange={(e) => handleSelectPlanId(e.target.value)}
              className="custom-plan"
            />
          </div>

          <div className="custom-plan-container">
            <Details onClick={() => handleReset()}>Reset</Details>
          </div>

          <FlexRow>
            <div className="custom-realtime-container">
              <input type="checkbox" id="checkbox" onChange={handleColored} checked={selectedColored} />
              <label htmlFor="checkbox"> colored</label>
            </div>
            <div className="custom-realtime-container">
              <input type="checkbox" id="checkbox" onChange={handleRealTimeChange} checked={intervalMs} />
              <label htmlFor="checkbox"> real-time</label>
            </div>
          </FlexRow>

          <div className="custom-loader-container">
            {(isRefetching || true )&& <Loader />}
          </div>
        </Flex>

        {isLoading ? (
          <>
            <br />
            <br />
            <Skeleton height="24px" width="100%" />
          </>
        ) : data?.data?.length > 0 ? (
          <>
            <ContainerTable
              header={
                <TableRow>
                  <TableHeader title="Date" align="left" />
                  <TableHeader title="Network" align="left" />
                  <TableHeader title="Task" align="left" />
                  <TableHeader title="Status" align="center" />
                  <TableHeader title="Details" align="left" />
                  <TableHeader title="Plan ID #i" align="left" />
                  <TableHeader title="" align="left" />
                </TableRow>
              }
            >
              {data?.data
                ?.map((task, i) => (
                  <LogsItem
                    key={i}
                    item={task}
                    index={i + 1}
                    width={width}
                    colored={selectedColored}
                    handleSelectPlanId={handleSelectPlanId}
                  />
                ))}
            </ContainerTable>
            <PaginationControls
              currentPage={page}
              totalPages={data?.pages}
              onPageChange={handlePageChange}
            />
          </>
        ) : (
          <EmptyState
            width={width}
            title={Object.keys(filters).length === 0 ? 'No logs' : 'No logs to show, yet.'}
            text={Object.keys(filters).length === 0 ? 'Try resetting the filters' : 'Data will appear here once the transaction is executed.'}
          />
        )}
      </Container>
    </ActivitySection>
  )
}

const ActivitySection = styled.section`
  padding: 20px 0;
  color: ${(props) => props.theme.textWhite};
  width: 100%;
  select {
    padding: 6px 13px 6px 16px;
    border: 0px;
    background: #232632;
    color: white;
    border-radius: 10px;
    font-family: 'DMSans';
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    outline: none !important;
  }

  
  .custom-select-container {
    position: relative;
    display: inline-block;
    margin-right: 20px;
  }

  .custom-plan-container {
    position: relative;
    display: inline-block;
    margin-right: 20px;
  }

  .custom-realtime-container {
    display: inline-block;
    color: rgb(118, 118, 118);
    font-size: 14px ;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .custom-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    margin-bottom: 21px;
  }

  .custom-select {
    padding: 6px 13px 6px 16px;
    min-width: 150px;
    border: 0px;
    background: rgba(168, 154, 255, 0.10);
    color: white;
    border-radius: 10px;
    font-family: 'DMSans';
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    outline: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .custom-plan {
    padding: 6px 13px 6px 16px;
    max-width: 100px;
    border: 0px;
    background: rgba(168, 154, 255, 0.10);
    color: white;
    border-radius: 10px;
    font-family: 'DMSans';
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    outline: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .arrow-container {
    position: absolute;
    transform: translateY(-50%);
    top: 55%;
    right: 13px;
  }

  .arrow-container img {
    height: 15px;
    width: 15px;
    object-fit: contain;
    height: 100%;
  }
`

const PaginationControls = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <Pagination>
      <PaginationButton onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1}>
        Previous
      </PaginationButton>
      <span>{`Page ${currentPage} of ${totalPages}`}</span>
      <PaginationButton onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages}>
        Next
      </PaginationButton>
    </Pagination>
  )
}

const Flex = styled.div`
 display: flex;
 justify-content: space-between;
 align-items: center;
 margin-bottom: 30px;
`

const FlexRow = styled.div`
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: flex-start;
`

const Pagination = styled.div`
margin-top: 40px;
span {
  margin: 0 20px;
}
`

const PaginationButton = styled.button`
  background: rgba(168, 154, 255, 0.10);
  color: white;
  border: 0px;
  padding: 5px 15px;
  border-radius: 10px;
  cursor: pointer;
  &:disabled {
    background: rgba(239, 239, 239, 0.3);
    color: rgba(16, 16, 16, 0.3);
  }
`

const Loader = styled.span`
  width: 30px;
  height: 26px;
  display: inline-block;
  position: relative;

&::after,
&::before {
  content: '';
  box-sizing: border-box;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 2px solid #6F5CE6;
  position: absolute;
  left: 0;
  top: 0;
  animation: animloader 2s linear infinite;
}
&::after {
  animation-delay: 1s;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
`


export const Details = styled.button`

  background: rgba(168, 154, 255, 0.10);
  transition: background-color 0.3s ease;
  color: white;
  border: 0px;
  padding: 10px 15px;
  border-radius: 10px;
  height: 43px;
  cursor: pointer;
  &:disabled {
    background: rgba(239, 239, 239, 0.3);
    color: rgba(16, 16, 16, 0.3);
  }
  &:hover {
      background: ${(props) => props.theme.main};
  }
`

export default Logs
