import React, { useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import Network from '../components/Network'
import TableRow from '../structure/Table/TableRow'
import TableCell from '../structure/Table/TableCell'
import defaultAction from '../assets/logs/Flow.svg'
import LogsDetail from './LogsDetail'
import LogsPanel from './LogsPanel'
import Status from '../components/Status'
import { TasksDictionary } from '../constants/tasksDictionary'
import { parseActionName } from '../utils/general-utils'
import { shortenHash } from '../utils/web3-utils'
import { Details } from '../styles/general'
import { Link } from 'react-router-dom'

const LogsItem = ({
  item,
  width,
  colored,
  handleSelectPlanId,
  lite = false,
}) => {
  const [isOpen, setOpen] = useState(false)
  const icon = TasksDictionary[item?.task?.executionType]?.icon
  const showStatusColored = colored && item?.status?.type
  return (
    <>
      <Row key={item?.id} status={showStatusColored}>
        <TableCell lite={lite}>
          <Success>
            <Status
              status={item?.status?.type}
              width={width}
              text={moment.unix(item?.executedAt).fromNow()}
            />
          </Success>
          <LogsPanel
            open={isOpen}
            item={item}
            onClose={() => setOpen(!isOpen)}
          />
        </TableCell>
        {!lite && (
          <TableCell align="left">
            <FlexNoWrap>
              <Network
                network={item?.chainId}
                width={width}
                small="false"
                noLogo={true}
              />
            </FlexNoWrap>
          </TableCell>
        )}
        <TableCell lite={lite}>
          {/* <Column > */}
          <Flex>
            <ActionIcon
              src={icon ? icon : defaultAction}
              alt={item?.task?.executionType}
            />
            {parseActionName(item?.task.name)}
          </Flex>
          {/* </Column> */}
        </TableCell>
        <TableCell align="left">
          <Label status={showStatusColored}>{item?.status?.type}</Label>
        </TableCell>
        <TableCell>
          <LogsDetail item={item} />
        </TableCell>
        <TableCell align="left">
          <FlexNoWrap>
            <Linked onClick={() => handleSelectPlanId(item?.planId)}>
              {shortenHash(item?.planId)}{' '}
            </Linked>
            {` #${item?.index} `}
          </FlexNoWrap>
        </TableCell>
        <TableCell>
          <Details onClick={() => setOpen(!isOpen)}>Details</Details>
        </TableCell>
      </Row>
    </>
  )
}

const statusStyles = {
  fail: {
    backgroundColor: '#DE0000',
    backgroundLabel: '#DE0000',
    colorLabel: '#fff',
  },
  reverted: {
    backgroundColor: '#7d23552e',
    backgroundLabel: '#7d23552e',
    colorLabel: '#fff',
  },
  success: {
    backgroundColor: '#33c2b036',
    backgroundLabel: '#33c2b0',
    colorLabel: '#fff',
  },
  simulatedOk: {
    backgroundColor: '#d2d31636',
    backgroundLabel: '#d2d31663',
    colorLabel: '#fff',
  },
  notSimulated: {
    backgroundColor: '#ef406f1f',
    backgroundLabel: '#ef406fb5',
    colorLabel: '#ffffffc7',
  },
  simulatedFail: {
    backgroundColor: '#80008012',
    backgroundLabel: '#80008063',
    colorLabel: '#ffffffc7',
  },
}

const Flex = styled.div`
  display: flex;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${(props) => (props.lite ? '200px' : 'none')};
`
const FlexNoWrap = styled.div`
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #ffffff80;
`

const Success = styled.div`
  padding-left: 10px;
  color: ${(props) => props.theme.success};
`

const ActionIcon = styled.img`
  height: 23px;
  margin-right: 15px;
  @media only screen and (max-width: 700px) {
    height: 17px;
    margin-right: 5px;
  }
`
const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 105px;
  background-color: ${(props) =>
    statusStyles[props.status]?.backgroundLabel || 'transparent'};
  color: ${(props) => statusStyles[props.status]?.colorLabel || '#fff'};
  padding: 2px 7px;
  border-radius: 5px;
`

const Row = styled(TableRow)`
  background-color: ${(props) =>
    statusStyles[props.status]?.backgroundColor || 'transparent'};
  &:hover {
    ${Details} {
      background: ${(props) => props.theme.main};
    }
  }
`

const Linked = styled(Link)`
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

export default LogsItem
