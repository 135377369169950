import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { Container } from '../styles/texts'
import Table from '../structure/Table/Table'
import TableRow from '../structure/Table/TableRow'
import TableHeader from '../structure/Table/TableHeader'
import Breadcrumbs from '../structure/Breadcrumbs'
import Network from '../components/Network'
import EmptyState from '../components/EmptyState'
import ActivityItem from '../sections/ActivityItem'
import { Heading1 } from '../styles/texts'
import { Skeleton } from '../styles/general'
import useRelayedExecutions from '../hooks/useRelayedExecutions'
import arrowDown from '../assets/arrow-down.svg'

const Activity = ({ width }) => {
  const params = useParams()
  const { data, isLoading } = useRelayedExecutions(params.id)
  const [selectedNetwork, setSelectedNetwork] = useState('')
  const large = 1000

  const buttonRef = useRef(null)

 const handleImageClick = () => {
   console.log('Imagen clicada')
   buttonRef.current.dispatchEvent(new Event('click', { bubbles: true }))
 }

  return (
    <ActivitySection>
      <Container>
        <Breadcrumbs />
        <Heading1>Activity</Heading1>
        {isLoading ? (
          <>
            <br />
            <br />
            <Skeleton height="24px" width="100%" />
          </>
        ) : data.length > 0 ? (
          <>
            <div className="custom-select-container">
              <select
                value={selectedNetwork}
                onChange={(e) => setSelectedNetwork(e.target.value)}
                className="custom-select"
                ref={buttonRef}
              >
                <option value="">All Networks</option>
                {Array.from(
                  new Set(
                    data.map((task) => task.smartVault.environment.network)
                  )
                ).map((network) => (
                  <option key={network} value={network}>
                    <Network network={network} noLogo={true}/>
                  </option>
                ))}
              </select>
              <div  id="arrow-container" className="arrow-container"  onClick={handleImageClick}>
                <img src={arrowDown} alt="Arrow" />
              </div>
            </div>

            <ContainerTable
              header={
                <TableRow>
                  <TableHeader title="When" align="left" />
                  <TableHeader title="Network" align="left" />
                  <TableHeader title="Task" align="left" />
                  {width >= large && (
                    <TableHeader title="Details" align="left" />
                  )}
                  {width >= large && (
                    <TableHeader title="Cost" align="left" />
                  )}
                </TableRow>
              }
            >
              {data
                ?.filter(
                  (action) =>
                    !selectedNetwork ||
                    action.smartVault.environment.network === selectedNetwork
                )
                .map((action, i) => (
                  <ActivityItem
                    key={i}
                    item={action}
                    index={i + 1}
                    width={width}
                  />
                ))}
            </ContainerTable>
          </>
        ) : (
          <EmptyState
            width={width}
            title="No logs to report just yet."
            text="Data will appear here once the transaction is executed."
          />
        )}
      </Container>
    </ActivitySection>
  )
}

const ContainerTable = styled(Table)`
  background-color: rgba(168, 154, 255, 0.12);
  border-radius: 24px;
  tbody tr:hover {
    background: rgba(0, 0, 0, 0.24);
  }
  th {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.50);
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  }
  td:first-child,
  th:first-child {
    padding-left: 25px;
    white-space: nowrap;
  }
  td:last-child,
  th:last-child {
    padding-right: 25px;
  }
  td {
    padding: 23px 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    vertical-align: top;
  }
  tr:last-child td {
    border-bottom: 0px solid;
  }
`

const ActivitySection = styled.section`
  height: auto;
  padding: 20px 0;
  color: ${(props) => props.theme.textWhite};
  width: 100%;
  margin: auto;

  .custom-select-container {
    position: relative;
    display: inline-block;
  }

  .custom-select {
    padding: 6px 13px 6px 16px;
    min-width: 150px;
    border: 0px;
    background: rgba(168, 154, 255, 0.10);
    color: white;
    border-radius: 10px;
    font-family: 'DMSans';
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    outline: none !important;
    margin-bottom: 30px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .arrow-container {
    position: absolute;
    transform: translateY(-50%);
    top: 35%;
    right: 13px;
  }

  .arrow-container img {
    height: 15px;
    width: 15px;
    object-fit: contain;
    height: 100%;
  }
`

export default Activity
