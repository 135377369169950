import React, { useState } from 'react'
import styled from 'styled-components'
import { Container } from '../styles/texts'
import Navbar from '../structure/Navbar'
import EnvironmentCard from '../sections/EnvironmentCard'
import { Heading2, Heading3, SmallText } from '../styles/texts'
import { Skeleton } from '../styles/general'
import { Link } from 'react-router-dom'
import useEnvironments from '../hooks/useEnvironments'
import Spotlight from '../sections/Spotlight'

const List = ({ width }) => {
  const environments = useEnvironments()
  const [isChildReady, setIsChildReady] = useState(false)

  const onChildReady = () => {
    setIsChildReady(true)
  }

  return (
    <ListSection>
      <Navbar />
      <>
        <TitleContainer>
          <SmallText>
            <Link to="/">Back</Link>
          </SmallText>

          <Heading2>Browse Environments</Heading2>
        </TitleContainer>
        <StyledContainer>
          <Heading3>
            {environments.isLoading
              ? ''
              : environments?.data?.length + ' RESULTS'}
          </Heading3>
        </StyledContainer>
        <ListContainer>
          {!isChildReady && <Skeleton height="24px" width="100%" />}

          {environments?.data?.map(item => (
            <EnvironmentCard
              environment={item}
              key={item.id}
              onReady={onChildReady}
            />
          ))}
        </ListContainer>
        <StyledContainer>
          <Heading3 style={{ marginTop: '100px', textAlign: 'left' }}>
            Spotlight
          </Heading3>
          <Spotlight />
        </StyledContainer>

      </>
    </ListSection>
  )
}
const StyledContainer = styled(Container)`
  max-width: 940px;
`

const ListSection = styled.section`
  height: auto;
  padding: 0 0 80px 0;
  color: ${props => props.theme.textWhite};
  width: 100%;
  margin: auto;
  min-height: 700px;
`

const TitleContainer = styled(StyledContainer)`
  display: flex;
  align-items: flex-start;
  padding: 50px 0;
  a {
    color: #a7acc1;
  }
  h1 {
    margin: auto;
    padding-right: 36px;
  }
`

const ListContainer = styled(StyledContainer)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
`

export default List
