import React, { useState } from 'react'
import styled from 'styled-components'
import Breadcrumbs from '../structure/Breadcrumbs'
import Permissions from '../sections/Permissions'
import TasksParams from '../sections/TasksParams'
import GeneralParams from '../sections/GeneralParams'
import Split from '../structure/Split'
import { useParams, Link } from 'react-router-dom'
import useEnvironmentTasks from '../hooks/useEnvironmentTasks'
import { parseActionName } from '../utils/general-utils'
import { Heading3, SmallText } from '../styles/texts'
import { Scrollbars } from 'react-custom-scrollbars'
import { ConfigContainer } from '../styles/general'
import { renderThumbVertical } from '../utils/general-utils'
import GeneralImplementations from '../sections/GeneralImplementations'
import warning from '../assets/warning.svg'

const Settings = ({ width }) => {
  const params = useParams()
  const tasks = useEnvironmentTasks(params.id)
  const action = params.action
    ? tasks?.data?.filter(item => item.name === params.action)[0]
    : ''

  return (
    <SettingsSection>
      <Split
        primary={
          <Scrollbars renderThumbVertical={renderThumbVertical}>
            <ConfigContainer style={{ paddingBottom: '0' }}>
              <Breadcrumbs />
            </ConfigContainer>
            {params.type === 'permissions' ? (
              action && <Permissions action={action} />
            ) : params.action === 'implementations' ? (
              <GeneralImplementations />
            ) : params.action ? (
              <TasksParams task={action} />
            ) : (
              <GeneralParams />
            )}
          </Scrollbars>
        }
        secondary={<Menu tasks={tasks?.data} />}
      />
    </SettingsSection>
  )
}

const Menu = ({ tasks = [] }) => {
  const params = useParams()
  const [active, setActive] = useState(
    params.action ? params.action : 'general'
  )
  const handleClick = e => {
    const index = e.target.id
    if (index !== active) {
      setActive(index)
    }
  }

  const sortedTasks = tasks
    ?.map((task, i) => ({
      ...task,
      obsolete: parseInt(task?.permissions, 10) > 0 ? 'false' : 'true',
    }))
    .sort((a, b) => {
      return parseInt(b.permissions, 10) - parseInt(a.permissions, 10)
    })

  return (
    <div className="scroll-container">
      <Tabs>
        <Heading3>Configuration</Heading3>
        <Title>Params</Title>
        <Link to={'/' + params.id + '/settings/'}>
          <Tab
            onClick={handleClick}
            id="general"
            active={active.toString() === 'general' ? 'true' : 'false'}
          >
            <SmallText id="general">General Params</SmallText>
          </Tab>
        </Link>

        {sortedTasks.map((task, i) => {
          return (
            <TabAction
              name={task.name}
              page="params"
              key={i + 1}
              handleClick={handleClick}
              obsolete={parseInt(task?.permissions, 10) > 0 ? 'false' : 'true'}
            />
          )
        })}
        <Title>Permissions</Title>
        {sortedTasks.map((task, i) => (
            <TabAction
              name={task.name}
              index={i + 1}
              key={i + 1}
              page="permissions"
              handleClick={handleClick}
              obsolete={task.obsolete}
            />
          ))}

        <Link to={'/' + params.id + '/settings/implementations/'}>
          <Tab
            onClick={handleClick}
            id="implementations"
            active={active.toString() === 'implementations' ? 'true' : 'false'}
          >
            <Title>Implementations</Title>
          </Tab>
        </Link>
      </Tabs>
    </div>
  )
}

const TabAction = ({ name = 'Action', permissions, page, handleClick, obsolete = 'false'}) => {
  const params = useParams()
  return (
    <Link to={'/' + params.id + '/settings/' + page + '/' + name}>
      <Tab
        onClick={handleClick}
        active={
          params.action === name && page === params.type ? 'true' : 'false'
        }
        id={name}
      >
        <SmallText id={name}>{obsolete === 'true' && <img src={warning} alt="!"/>}{parseActionName(name)}</SmallText>
      </Tab>
    </Link>
  )
}

const SettingsSection = styled.section`
  height: calc(100vh - 160px);
  padding: 0;
  color: ${props => props.theme.textWhite};
  width: 100%;
  display: flex;
  z-index: 0;
  table tr:last-child {
    td {
      border-bottom: 0px solid transparent;
    }
  }

`

const Title = styled(SmallText)`
  font-weight: bold;
  color: ${props => props.theme.textWhite};
`
const Tabs = styled.div`
  padding: 40px 30px;
  @media only screen and (max-width: 700px) {
    padding: 0px 30px;
  }

  height: 100%;
  box-sizing: border-box;
`

const Tab = styled.div`
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #242734;
  }
  padding: 0 10px;
  margin: 10px -10px;
  background-color: ${props =>
    props.active === 'true' ? '#242734' : 'transparent'};
  color: ${props => (props.active === 'true' ? '#fff' : '#A7acc1')};
  transition: all 0.3s ease-in-out;
  a {
    text-decoration: none !important;
  }

  &:hover {
    background-color: #242734;
    color: transparent;
    background: linear-gradient(to right, white 70%, transparent);
    -webkit-background-clip: text;
    background-clip: text;
  }

  white-space: nowrap;
  overflow: hidden;
  position: relative;
  overflow: hidden;

  color: transparent;
  background: linear-gradient(to right, #A7ACC1 70%, transparent);
  -webkit-background-clip: text;
  background-clip: text;


  p {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    img {
      height: 20px;
    }
  }
`

export default Settings
