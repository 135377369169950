import { useQuery } from '@tanstack/react-query'
import { request, gql } from 'graphql-request'
import { CHAIN_SUBGRAPH_URL } from '../constants/chainInfo'

const useRelayedExecutions = (id, daysAgo) => {
  const { data, isLoading } = useQuery(
    ['useRelayedExecutions', id, daysAgo],
    () => fetchAllRelayedExecutions(id, daysAgo)
  )

  return { data, isLoading }
}

const fetchRelayedExecutions = async (chainId, id, daysAgo) => {
  let filter = {}

  if (daysAgo) {
    const date = new Date()
    date.setDate(date.getDate() - daysAgo)
    const timestampInSeconds = Math.floor(date.getTime() / 1000)
    filter.executedAt_gte = timestampInSeconds
  }
  let data = ''
  if (id) {
    data = await request(
      CHAIN_SUBGRAPH_URL[chainId],
      gql`
    query Relayed($filter: RelayedExecution_filter) {
      environment(id: ${'"' + id.toLowerCase() + '"'}) {
        network
        namespace
        relayedExecutions(where: $filter, orderBy: executedAt, orderDirection: desc) {
          executedAt
          gasUsed
          gasPrice
          costUSD
          task {
            id
            name
            executionType
            taskConfig
          }
          transaction {
            hash
            sender
          }
          id
          index
          movements {
            added
            amount
            token {
              decimals
              symbol
              id
              name
            }
          }
          smartVault {
            environment {
              network
            }
          }
          succeeded
        }
      }
    }
    `,
      { filter }
    )
  }

  return data?.environment ? data?.environment?.relayedExecutions : null
}

const fetchAllRelayedExecutions = async (id, daysAgo) => {
  let data = []
  for (const chainId in CHAIN_SUBGRAPH_URL) {
    try {
      const relayedByChain = await fetchRelayedExecutions(chainId, id, daysAgo)

      if (relayedByChain) {
        data.push(...relayedByChain)
      }
    } catch (error) {
      console.error('Error fetching relayed executions:', error, 'chain:', chainId)
    }
  }

  return data.sort((a, b) => b.executedAt - a.executedAt)
}

export default useRelayedExecutions
