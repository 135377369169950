import React, { useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import Network from '../components/Network'
import TableRow from '../structure/Table/TableRow'
import TableCell from '../structure/Table/TableCell'
import defaultAction from '../assets/logs/Flow.svg'
import ActivityDetail from './ActivityDetail'
import ActivityFailedReason from './ActivityFailedReason'
import ActivityPanel from './ActivityPanel'
import Status from '../components/Status'
import { TasksDictionary } from '../constants/tasksDictionary'
import { parseActionName, formattedNumber } from '../utils/general-utils'
import { formatTokenAmount } from '../utils/math-utils'

const ActivityItem = ({ item, width }) => {
  const large = 1000
  const [isOpen, setOpen] = useState(false)
  return (
    <>
      <Row key={item?.id} onClick={() => setOpen(!isOpen)}>
        <TableCell>
          <Success>
            <Status
              status={item?.succeeded === true ? 'success' : 'fail'}
              width={width}
              text={moment.unix(item?.executedAt).fromNow()}
            />
          </Success>
          <ActivityPanel
            title={'success message'}
            primitives={[]}
            open={isOpen}
            item={item}
            onClose={() => setOpen(!isOpen)}
          />
        </TableCell>
        <TableCell align="left">
          <Network
            network={item?.smartVault?.environment?.network}
            width={width}
          />
        </TableCell>
        <TableCell>
          <Column>
            <Flex>
              <ActionIcon
                src={
                  TasksDictionary[item?.task?.executionType]?.icon
                    ? TasksDictionary[item?.task?.executionType]?.icon
                    : defaultAction
                }
                alt=""
              />
              {parseActionName(item?.task.name)}
            </Flex>
          </Column>
        </TableCell>
        {width >= large && (
          <>
            <TableCell>
              {item?.succeeded === true ?
                <ActivityDetail movements={item?.movements} /> :
                <ActivityFailedReason item={item} />
              }
            </TableCell>

            <TableCell>
              {formattedNumber(
                formatTokenAmount(item?.costUSD, 6, {
                  digits: 2,
                  symbol: 'USD',
                })
              )}
            </TableCell>
          </>
        )}
      </Row>
    </>
  )
}
const Row = styled(TableRow)`
  cursor: pointer;
`

const Flex = styled.div`
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Success = styled.div`
  color: ${(props) => props.theme.success};
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const ActionIcon = styled.img`
  height: 23px;
  margin-right: 15px;
  @media only screen and (max-width: 700px) {
    height: 17px;
    margin-right: 5px;
  }
`
export default ActivityItem
