import { useQuery } from '@tanstack/react-query'
import { request, gql } from 'graphql-request'
import { CHAIN_SUBGRAPH_URL } from '../constants/chainInfo'

const useEnvironment = id => {
  return useQuery(['useEnvironments'], () => fetchAllTasks(id))
}

const fetchEnvironment = async (chainId, id) => {
  let environment = await request(
    CHAIN_SUBGRAPH_URL[chainId],
    gql`
    query Environment {
      environment(id: ${'"' + id.toLowerCase() + '"'}) {
        tasks {
          id
          name
          permissions
        }
      }
    }
    `
  )

  return environment?.environment?.tasks
}

const fetchAllTasks = async id => {
  let allTasks = []
  for (const chainId in CHAIN_SUBGRAPH_URL) {
    const configs = await fetchEnvironment(chainId, id)
    if (configs) {
      configs.forEach(obj => {
        if (!allTasks.some(item => item.id === obj.id)) {
          allTasks.push(obj)
        }
      })
    }
  }

  return allTasks
}

export default useEnvironment
