import { useQuery, useQueryClient } from '@tanstack/react-query'
import { request, gql } from 'graphql-request'
import { CHAIN_SUBGRAPH_URL } from '../constants/chainInfo'
import { formatTokenAmount } from '../utils/math-utils'
import moment from 'moment'

const useTaskConfig = id => {
  const queryClient = useQueryClient()

  const { data, isLoading, isError } = useQuery(
    ['useTaskConfig', id],
    () => fetchAllTaskConfig(id),
    {
      invalidateOnMount: true,
    }
  )

  const refetch = () => {
    queryClient.invalidateQueries(['useTaskConfig', id])
  }

  return {
    data,
    isLoading,
    isError,
    refetch,
  }
}

const fetchTaskConfig = async (chainId, id) => {
  if (!id) {
    return null
  }
  let taskConfig = await request(
    CHAIN_SUBGRAPH_URL[chainId],
    gql`
      query TasksQuery {
        task(id: ${'"' + id.toLowerCase() + '"'}) {
          id
          name
          taskConfig {
            connector
            acceptanceList {
              id
              tokens
              type
            }
            customMaxSlippages {
              id
              maxSlippage
              token {
                decimals
                id
                name
                symbol
              }
            }
            customTokenOuts {
              tokenOut {
                decimals
                id
                name
                symbol
              }
            }
            defaultTokenThreshold {
             max
             min
             token {
               decimals
               id
               name
               symbol
             }
           }
            customTokenThresholds {
              threshold {
                id
                max
                min
                token {
                  decimals
                  id
                  name
                  symbol
                }
              }
            }
            customVolumeLimits {
              volumeLimit {
                amount
                id
                period
                token {
                  decimals
                  id
                  name
                  symbol
                }
              }
            }
            gasLimits {
               gasPriceLimit
               priorityFeeLimit
               txCostLimit
               txCostLimitPct
             }
            defaultDestinationChain
            defaultMaxSlippage
            nextBalanceConnector
            previousBalanceConnector
            recipient
            timeLock {
              window
              mode
              allowedAt
              frequency
            }
          }
        }
      }
    `
  )

  if (taskConfig && taskConfig.task) {
    const transformedResponse = {
      address: [
        {
          network: parseInt(chainId),
          address: taskConfig.task.id,
        },
      ],
      recipient: [
        {
          network: parseInt(chainId),
          recipient: taskConfig.task.taskConfig.recipient,
        },
      ],

      custom: [
        {
          network: parseInt(chainId),
          customMaxSlippages: taskConfig.task.taskConfig.customMaxSlippages,
          customTokenOuts: taskConfig.task.taskConfig.customTokenOuts,
          customTokenThresholds:
            taskConfig.task.taskConfig.customTokenThresholds,
          customVolumeLimits: taskConfig.task.taskConfig.customVolumeLimits,
          defaultMaxSlippage: formatTokenAmount(taskConfig.task.taskConfig.defaultMaxSlippage, 18, {digits: 2}) * 100 + ' %',
        },
      ],
      acceptanceList: [
        {
          network: parseInt(chainId),
          token: taskConfig.task.taskConfig.acceptanceList.tokens[0],
        },
      ],
      limit: [],
      connector: [
        {
          network: parseInt(chainId),
          previousBalanceConnector:
            taskConfig.task.taskConfig.previousBalanceConnector,
          nextBalanceConnector: taskConfig.task.taskConfig.nextBalanceConnector,
          connector: taskConfig.task.taskConfig.connector,
        },
      ],
      timeLock: [],
      defaultTokenThreshold: [],
      default: [],
    }

    if (taskConfig?.task?.taskConfig?.gasLimits) {
      const costLimit = taskConfig.task.taskConfig.gasLimits.txCostLimitPct

      transformedResponse.limit.push({
        network: parseInt(chainId),
        txCostLimit: taskConfig.task.taskConfig.gasLimits.txCostLimit,
        txCostLimitPct: (formatTokenAmount(costLimit, 18, {digits: 2}) * 100) + ' %',
        priorityFeeLimit: taskConfig.task.taskConfig.gasLimits.priorityFeeLimit,
        gasPriceLimit: formatTokenAmount(taskConfig.task.taskConfig.gasLimits.gasPriceLimit, 9, {digits: 2}) +' Gwei',
      })
    }
    if (taskConfig?.task?.taskConfig?.timeLock) {
      const windowValue = taskConfig.task.taskConfig.timeLock.window
      const frecuency = taskConfig.task.taskConfig.timeLock.frequency
      const allowedAt = taskConfig.task.taskConfig.timeLock.allowedAt
      transformedResponse.timeLock.push({
        network: parseInt(chainId),
        window: windowValue + ' ('+ moment.duration(windowValue, 'seconds').asDays() +' days)',
        mode: taskConfig.task.taskConfig.timeLock.mode,
        allowedAt: allowedAt + ' ('+ moment.unix(allowedAt).utc().format('YYYY-MM-DD h:mm A [GMT]')+')',
        frequency: frecuency + ' ('+ moment.duration(frecuency, 'seconds').asDays() +' days)',
      })
    }
    if (taskConfig?.task?.taskConfig?.defaultTokenThreshold) {
      transformedResponse.defaultTokenThreshold.push({
        network: parseInt(chainId),
        minAmount: {
          amount: taskConfig.task.taskConfig.defaultTokenThreshold.min,
          token: taskConfig.task.taskConfig.defaultTokenThreshold.token,
        },
        maxAmount: {
          amount: taskConfig.task.taskConfig.defaultTokenThreshold.max,
          token: taskConfig.task.taskConfig.defaultTokenThreshold.token,
        },
      })
    }
    if (taskConfig?.task?.taskConfig?.defaultDestinationChain) {
      transformedResponse.default.push({
        network: parseInt(chainId),
        defaultDestinationChain:
          taskConfig.task.taskConfig.defaultDestinationChain,
      })
    }

    return transformedResponse
  }
  return null
}

const fetchAllTaskConfig = async id => {
  let allConfigs = {
    Address: [],
    'Acceptance List': [],
    Limit: [],
    Connector: [],
    'Time Lock': [],
    'Default Destination Chain': [],
    Custom: [],
    Recipient: [],
    'Default Token Threshold': [],
  }
  for (const chainId in CHAIN_SUBGRAPH_URL) {
    const configs = await fetchTaskConfig(chainId, id)
    if (configs) {
      allConfigs['Address'].push(...configs.address)
      allConfigs['Acceptance List'].push(...configs.acceptanceList)
      allConfigs['Limit'].push(...configs.limit)
      allConfigs['Connector'].push(...configs.connector)
      allConfigs['Time Lock'].push(...configs.timeLock)
      allConfigs['Default Destination Chain'].push(...configs.default)

      if (configs.recipient[0].recipient) {
        allConfigs['Recipient'].push(...configs.recipient)
      }

      if (configs.defaultTokenThreshold) {
        allConfigs['Default Token Threshold'].push(
          ...configs.defaultTokenThreshold
        )
      }
      if (
        configs.custom[0].customMaxSlippages.length > 0 ||
        configs.custom[0].customTokenOuts.length > 0 ||
        configs.custom[0].customTokenThresholds.length > 0 ||
        configs.custom[0].customVolumeLimits.length > 0 ||
        configs.custom[0].defaultMaxSlippage
      ) {
        allConfigs['Custom'].push(...configs.custom)
      }
    }
  }

  return allConfigs
}

export default useTaskConfig
