import { useQuery } from '@tanstack/react-query'
import { request, gql } from 'graphql-request'
import { CHAIN_SUBGRAPH_URL } from '../constants/chainInfo'
import { formatTokenAmount, percent } from '../utils/math-utils'

const useSmartVaultConfig = id => {
  const data = useQuery(['useSmartVaults', id], () =>
    fetchAllSmartVaultConfigs(id)
  )
  return data
}

const fetchSmartVaultConfig = async (chainId, id) => {
  let smartVault = await request(
    CHAIN_SUBGRAPH_URL[chainId],
    gql`
    query SmartVault {
      environment(id: ${'"' + id.toLowerCase() + '"'}) {
        network
        smartVaults {
          id
           priceOracle {
             feeds {
               feed
               id
               quote {
                 decimals
                 id
                 name
                 symbol
               }
               base {
                 decimals
                 id
                 name
                 symbol
               }
             }
             name
             signers {
               signer
             }
           }
           registry
           relayerconfig {
             balance
             feeCollector
             maxQuota
             quotaUsed
             nativeToken {
               decimals
               id
               name
               symbol
             }
           }
           smartVaultFee {
             maxFeePercentage
             feePercentage
             feeCollector
           }
        }
      }
    }
    `
  )

  if (smartVault && smartVault.environment) {
    const { network } = smartVault.environment
    const sv = smartVault.environment.smartVaults[0]
    const signers = sv.priceOracle?.signers?.map(obj => ({
      network: network,
      ...obj,
    }))

    const feeds = sv.priceOracle?.feeds?.map(obj => ({
      network: network,
      ...obj,
    }))

    const transformedResponse = {
      address: {
        network: network,
        address: sv.id,
      },
      priceOracleSigners: signers,
      priceOracleFeeds: feeds,
      registry: {
        network,
        address: sv.registry,
      },
      relayerconfig: {
        network: network,
        balance: sv?.relayerconfig?.balance
          ? formatTokenAmount(
              sv?.relayerconfig?.balance,
              sv?.relayerconfig?.nativeToken.decimals,
              { digits: 4, symbol: sv.relayerconfig.nativeToken.symbol }
            )
          : '',
        maxQuota: sv.relayerconfig?.maxQuota
          ? formatTokenAmount(
              sv?.relayerconfig.maxQuota,
              sv?.relayerconfig.nativeToken.decimals,
              { digits: 4, symbol: sv?.relayerconfig.nativeToken.symbol }
            )
          : '',
        quotaUsed: sv.relayerconfig?.quotaUsed
          ? formatTokenAmount(
              sv?.relayerconfig.quotaUsed,
              sv?.relayerconfig.nativeToken.decimals,
              { digits: 4, symbol: sv.relayerconfig.nativeToken.symbol }
            )
          : '',
        feeCollector: sv?.relayerconfig?.feeCollector,
      },
      smartVaultFee: {
        network,
        feeCollector: sv?.smartVaultFee?.feeCollector,
        feePercentage: sv?.smartVaultFee?.feePercentage
          ? percent(
              formatTokenAmount(sv?.smartVaultFee?.feePercentage, 18, {
                digits: 4,
              })
            )
          : '-',
        maxFeePercentage: sv?.smartVaultFee?.maxFeePercentage
          ? percent(
              formatTokenAmount(sv?.smartVaultFee?.maxFeePercentage, 18, {
                digits: 4,
              })
            )
          : '-',
      },
    }
    return transformedResponse
  }

  return null
}

const fetchAllSmartVaultConfigs = async id => {
  let allConfigs = {
    'Smart Vault Address': [],
    Relayer: [],
    'Smart Vault fee': [],
    'Price Oracle Signers': [],
    'Price Oracle Feeds': [],
    Registry: [],
  }
  for (const chainId in CHAIN_SUBGRAPH_URL) {
    const configs = await fetchSmartVaultConfig(chainId, id)
    if (configs) {
      allConfigs['Price Oracle Signers'].push(...configs.priceOracleSigners)
      allConfigs['Price Oracle Feeds'].push(...configs.priceOracleFeeds)
      allConfigs['Registry'].push(configs.registry)
      allConfigs['Smart Vault Address'].push(configs.address)
      allConfigs['Relayer'].push(configs.relayerconfig)
      allConfigs['Smart Vault fee'].push(configs.smartVaultFee)
    }
  }

  return allConfigs
}

export default useSmartVaultConfig
