import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import Navbar from './Navbar'
import MobileMenu from './MobileMenu'
import Menu from './SideBar'
import background from '../assets/background-layer.svg'

function Dashboard({ children }) {
  const [width, setWidth] = useState(null)
  const getWidth = () => divRef?.current?.offsetWidth
  const medium = 700
  const divRef = useRef(null)

  const [totalWidth, setTotalWidth] = useState(window.innerWidth)
  useEffect(() => {
    window.addEventListener('resize', () => setTotalWidth(window.innerWidth))
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setWidth(getWidth())
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])


  return (
    <Container>
      <StickyNavbar>
        <Navbar />
      </StickyNavbar>
      <Content>
        {totalWidth < medium ? (
          <MobileMenu />
        ) : (
          <SidebarMenu>
            <Menu />
          </SidebarMenu>
        )}
        <MainContent background={background} ref={divRef}>
          {React.Children.map(children, child => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, { width })
            }
            return child
          })}
        </MainContent>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
`

const Content = styled.div`
  flex: 1;
  display: flex;
  @media only screen and (max-width: 700px) {
    display: block;
  }
`

const SidebarMenu = styled.div`
  width: '84px';
  position: sticky;
  top: 60px;
  height: calc(100vh - 80px);
  transition: width 0.3s ease-in-out;
`

const MainContent = styled.div`
  background: url(${props => props.background}) no-repeat top center;
  background-size: cover;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  color: white;
  transition: width 0.3s ease-in-out;
  height: calc(100vh - 80px);
  width: calc(100vw - 84px);

  @media only screen and (max-width: 700px) {
    width: 100%;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #191B23;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #5E5F65;
    border: 2px solid #191B23;
  }
`

const StickyNavbar = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 70px;
`

export default Dashboard
