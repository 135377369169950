import React, { useState } from 'react'
import styled from 'styled-components'
import { Container } from '../styles/texts'
import EnvironmentCard from './EnvironmentCard'
import useEnvironments from '../hooks/useEnvironments'
import { EnvironmentsList } from '../constants/environmentsList'
import { Skeleton } from '../styles/general'

const Cards = () => {
  const environments = useEnvironments()
  const [isChildReady, setIsChildReady] = useState(false)

  const onChildReady = () => {
    setIsChildReady(true)
  }

  return (
    <>
      {!isChildReady && <Skeleton height="24px" width="100%" />}
      <ListContainer>
        {Object.keys(EnvironmentsList).map(key => {
          const matchingEnvironment = environments?.data?.find(
            item => item.id === key
          )
          const environmentToRender =
            matchingEnvironment || EnvironmentsList[key]
          return (
            <EnvironmentCard
              environment={environmentToRender}
              v2={!matchingEnvironment}
              key={key}
              onReady={onChildReady}
            />
          )
        })}
      </ListContainer>
    </>
  )
}

const ListContainer = styled(Container)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  width: 1236px;
  max-width: 95vw;
  min-height: 400px;
  margin: 0;
  margin-left: -46px;
  @media only screen and (max-width: 700px) {
    justify-content: center;
    margin-left: 0;
  }
`

export default Cards
