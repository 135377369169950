import React from 'react'
import styled from 'styled-components'
import { formatTokenAmount } from '../utils/math-utils'
import { tokens } from '../constants/tokens'

const TokenAmount = ({ tokenAmount }) => {
  const tokenImg = tokens.find(
    element => element?.symbol === tokenAmount.token?.symbol
  )

  return (
    <Container>
      <span>
        {formatTokenAmount(tokenAmount.amount, tokenAmount.token.decimals, {
          digits: 4,
        })}
      </span>
      <span>{tokenAmount.token.symbol}</span>
      <span>{tokenImg && <img src={tokenImg.logoURI} alt="Token" />}</span>
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  gap: 7px;
  span {
    margin-right: 5px;
  }
 img {
      height: 20px;
      object-fit: scale-down;
      margin: 0px 5px -4px 4px;
`

export default TokenAmount
