import React from 'react'
import { useParams, Link } from 'react-router-dom'
import moment from 'moment'
import styled from 'styled-components'
import Network from '../components/Network'
import Table from '../structure/Table/Table'
import TableRow from '../structure/Table/TableRow'
import TableHeader from '../structure/Table/TableHeader'
import TableCell from '../structure/Table/TableCell'
import useRelayedExecutions from '../hooks/useRelayedExecutions'

const ActiveNetworks = () => {
  const params = useParams()
  const { data } = useRelayedExecutions(params.id)

  const groupedData = {}

  data?.forEach((item) => {
    const network = item.smartVault?.environment?.network
    if (network) {
      if (!groupedData[network]) {
        groupedData[network] = {
          count: 0,
          mostRecentTimestamp: 0,
        }
      }
      groupedData[network].count++
      const timestamp = parseInt(item.executedAt, 10)
      if (timestamp > groupedData[network].mostRecentTimestamp) {
        groupedData[network].mostRecentTimestamp = timestamp
      }
    }
  })

  return (
    <>
      <Table
        header={
          <TableRow>
            <TableHeader title="Network" align="left" />
            <TableHeader title="Total runs" align="left" />
            <TableHeader title="Last run" align="left" />
            <TableHeader title=" " align="left" />
          </TableRow>
        }
      >
        {Object.keys(groupedData).map((network, index) => (
          <TableRow key={index}>
            <TableCell>
              <Network network={network} />
            </TableCell>
            <TableCell>{groupedData[network].count}</TableCell>
            <TableCell>
              {moment.unix(groupedData[network].mostRecentTimestamp).fromNow()}
            </TableCell>
            <TableCell>
              <Link to={'/' + params.id + '/activity'}>
                <View>View activity</View>
              </Link>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </>
  )
}

export default ActiveNetworks

const View = styled.div`
  color: #6f5ce6;
  font-family: "DMSansBold";
  font-weight: 700;
`
