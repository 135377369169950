import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import useRelayedExecutions from '../hooks/useRelayedExecutions'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
)

const colors = {
  purple: {
    default: 'rgba(111, 92, 230, 1)',
    half: 'rgba(111, 92, 230, 0.5)',
    quarter: 'rgba(111, 92, 230, 0.25)',
    zero: 'rgba(111, 92, 230, 0)',
  },
}

export const optionsMobile = {
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: { display: false },
    y: { display: false },
  },
}

export const options = {
  plugins: {
    legend: {
      position: 'top',
      display: false,
    },
    title: {
      display: false,
      text: 'Tasks Completed Per Day',
    },
    tooltip: {
      backgroundColor: '#6F5CE6',
      bodyFontColor: '#fff',
      titleFontColor: '#fff',
      titleFont: {
        weight: 'bold',
        size: 18,
      },
      bodyFont: {
        size: 15,
        weight: 'normal',
      },
      bodySpacing: 13,
      cornerRadius: 8,
      padding: 15,
      caretPadding: 17,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
  interaction: {
    mode: 'index',
    intersect: false,
    axis: 'x',
    radius: 40,
  },
}

const TasksChart = () => {
  const chartRef = useRef()
  const [width, setWidth] = useState(null)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    handleResize() // Establecer el ancho inicial
    window.addEventListener('resize', handleResize) // Agregar listener de evento de cambio de tamaño de ventana
    return () => {
      window.removeEventListener('resize', handleResize) // Eliminar listener de evento al desmontar el componente
    }
  }, [])

  const medium = 900
  const isMobile = width <= medium

  const params = useParams()
  const { data, isLoading } = useRelayedExecutions(params.id)

  const getTaskDateRange = () => {
    if (!isLoading && data && data.length > 0) {
      const minDate = new Date(
        Math.min(...data.map(task => task.executedAt)) * 1000
      )
      const maxDate = new Date(
        Math.max(...data.map(task => task.executedAt)) * 1000
      )
      return getDateRange(minDate, maxDate)
    }
    return []
  }

  const getDateRange = (startDate, endDate) => {
    const dateRange = []
    let currentDate = new Date(startDate)

    while (currentDate <= endDate) {
      dateRange.push(currentDate.toISOString().split('T')[0])
      currentDate.setDate(currentDate.getDate() + 1)
    }

    return dateRange
  }

  const dateRange = getTaskDateRange()

  const processData = () => {
    if (!isLoading) {
      const taskCounts = {}
      data?.forEach(task => {
        const timestamp = new Date(task.executedAt * 1000)
        // Format the date as YYYY-MM-DD
        const dateKey =
          timestamp.getFullYear() +
          '-' +
          ('0' + (timestamp.getMonth() + 1)).slice(-2) +
          '-' + // Months are 0-based
          ('0' + timestamp.getDate()).slice(-2)
        if (taskCounts[dateKey]) {
          taskCounts[dateKey]++
        } else {
          taskCounts[dateKey] = 1
        }
      })

      const dateLabels = dateRange
      const taskCountsByDate = dateRange.map(date => taskCounts[date] || 0)

      return { dateLabels, taskCountsByDate }
    }
    return { dateLabels: [], taskCountsByDate: [] }
  }

  const { dateLabels, taskCountsByDate } = processData()

  useEffect(
    () => {
      if (!isLoading && chartRef.current) {
        const chart = chartRef.current
        if (chart.canvas) {
          // Ensure the canvas element is present
          const ctx = chart.canvas.getContext('2d')
          const gradient = ctx.createLinearGradient(0, 0, 0, chart.height)
          gradient.addColorStop(0, colors.purple.half)
          gradient.addColorStop(0.35, colors.purple.quarter)
          gradient.addColorStop(1, colors.purple.zero)
          chart.data.datasets[0].backgroundColor = gradient
          chart.update()
        }
      }
    },
    [isLoading, chartRef, dateLabels, taskCountsByDate]
  ) // Add dependencies to the array

  const chartData = {
    labels: dateLabels,
    datasets: [
      {
        fill: true,
        label: 'Tasks Completed',
        data: taskCountsByDate,
        borderColor: colors.purple.default,
        backgroundColor: colors.purple.half,
        pointBackgroundColor: colors.purple.default,
        lineTension: 0.8,
        pointRadius: 7,
        pointHoverRadius: 10,
        pointHoverBackgroundColor: '#6f5ce6',
        pointHoverBorderColor: '#6f5ce6',
      },
    ],
  }

  return (
    <Line
      ref={chartRef}
      options={isMobile ? optionsMobile : options}
      data={chartData}
    />
  )
}

export default TasksChart
