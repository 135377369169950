import React, { useState } from 'react'
import styled from 'styled-components'
import AccordionItem from '../sections/AccordionItem'
import ParamTable from '../sections/ParamTable'
import { Skeleton } from '../styles/general'
import { Heading1, LargeText, StandardText } from '../styles/texts'
import { ConfigContainer } from '../styles/general'
import { parseActionName } from '../utils/general-utils'
import usePermissions from '../hooks/usePermissions'

const Permissions = ({ width, action }) => {
  const { data, isLoading } = usePermissions(action?.id || '')
  const [active, setActive] = useState(null)
  const handleToggle = index => {
    if (active === index) {
      setActive(null)
    } else {
      setActive(index)
    }
  }

  return (
    <ConfigContainer style={{paddingTop: '0'}}>
      <Heading1>{action && parseActionName(action?.name)} Permissions</Heading1>
      {isLoading ? (
        <Skeleton height="24px" width="100%" />
      ) : data ? (
        data.map(item => {
          return (
            <AccordionItem
              key={item.who}
              active={active}
              handleToggle={handleToggle}
              id={item.who}
              header={<LargeText>{item.who}</LargeText>}
              content={
                <FlexContent>
                  <ParamTable
                    data={[
                      {
                        network: item.chainId,
                        method: item.method,
                      },
                    ]}
                  />
                </FlexContent>
              }
            />
          )
        })
      ) : (
        <StandardText>No permissions found</StandardText>
      )}
    </ConfigContainer>
  )
}

const FlexContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 70px;
  p {
    max-width: 200px;
  }
  @media only screen and (max-width: 700px) {
    display: block;
  }
`
export default Permissions
