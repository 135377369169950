import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { Container } from '../styles/texts'
import TasksChart from '../sections/TasksChart'
import Breadcrumbs from '../structure/Breadcrumbs'
import ActiveNetworks from '../sections/ActiveNetworks'
import EmptyState from '../components/EmptyState'
import { Skeleton } from '../styles/general'
import { Heading1, Heading3 } from '../styles/texts'
import useRelayedExecutions from '../hooks/useRelayedExecutions'

const Overview = ({ width }) => {
  const params = useParams()
  const { data, isLoading } = useRelayedExecutions(params.id, 1)
  const { data: data2, isLoading: isLoading2 } = useRelayedExecutions(params.id)

  return (
    <OverviewSection>
      <Container>
        <Breadcrumbs />
        <Heading1 style={{ marginTop: '80px' }}>
          {isLoading ? (
            <Skeleton height="24px" width="480px" />
          ) : (
            <>
              {!isLoading2 && data2?.length > 0 ? (
                <>
                  {data?.length ? (
                    <>
                      {data?.length} tasks completed
                      <br /> over the last 24 hours
                    </>
                  ) : (
                    'No new task over the last 24 hours'
                  )}
                </>
              ) : (
                <>
                  Hold tight: we’re about
                  <br />
                  to start diving!
                </>
              )}
            </>
          )}
        </Heading1>
        {isLoading2 ? (
          <Skeleton height="570px" width="100%" />
        ) : (
          <>
            <Heading3 style={{ marginTop: '32px', textAlign: 'left' }}>
              TOTAL TASKS
            </Heading3>
            {data2?.length > 0 ? (
              <TasksChart />
            ) : (
              <EmptyState text="Our octo is out exploring data for you. Will return with a chart soon!" width={width} />
            )}
          </>
        )}
        <br />
        <br />
        <br />
        <br />
        <Heading3>MOST ACTIVE NETWORKS</Heading3>
        <ActiveNetworks />
        <br />
        <br />
      </Container>
    </OverviewSection>
  )
}

const OverviewSection = styled.section`
  height: auto;
  padding: 20px 0;
  color: ${props => props.theme.textWhite};
  width: 100%;
  margin: auto;
`

export default Overview
