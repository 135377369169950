import React, { useState } from 'react'
import AccordionItem from '../sections/AccordionItem'
import ParamTable from '../sections/ParamTable'
import { Heading1, Heading2, StandardText } from '../styles/texts'
import { Skeleton } from '../styles/general'
import { ConfigContainer } from '../styles/general'
import { parseActionName } from '../utils/general-utils'
import useTaskConfig from '../hooks/useTaskConfig'

const TasksParams = ({ width, task }) => {
  const [active, setActive] = useState('Address')
  const handleToggle = index => {
    if (active === index) {
      setActive(null)
    } else {
      setActive(index)
    }
  }

  const { data, isLoading } = useTaskConfig(task?.id)

  return (
    <ConfigContainer style={{ paddingTop: '0' }}>
      <Heading1>
        {task ? (
          parseActionName(task?.name) + ' Params'
        ) : (
          <Skeleton height="24px" width="100%" />
        )}{' '}
      </Heading1>
      {isLoading ? (
        <Skeleton height="24px" width="100%" />
      ) : data ? (
        Object.keys(data).map(
          key =>
            data[key] &&
            data[key].length > 0 && (
              <AccordionItem
                key={key}
                active={active}
                handleToggle={handleToggle}
                id={key}
                header={<Heading2>{key}</Heading2>}
                content={<ParamTable data={data[key]} />}
              />
            )
        )
      ) : (
        <StandardText>No params found</StandardText>
      )}
    </ConfigContainer>
  )
}

export default TasksParams
