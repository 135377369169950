import thegraph from '../assets/environments/The-Graph.svg'
import oneinch from '../assets/environments/1Inch.svg'
import paraswap from '../assets/environments/paraswap.svg'
import balancer from '../assets/environments/Balancer.svg'

export const EnvironmentsList = {
  '0x93e4eb250117a846013a0724340b72f4313d5208742dd7ac55599caacb9421b4': {
    name: 'The Graph Refunder',
    logo: thegraph,
  },
  '0xcb62e34ae5c64e59518dd829381bf6d2afb5d52e4ca9c50c5b0d1cd30c98ea9f' : {
    logo: oneinch,
  },
  '0xd5b927956057075377263aab7f8afc12f85100db': {
    name: 'Paraswap Fee Collector',
    logo: paraswap,
    id: '0xd5b927956057075377263aab7f8afc12f85100db',
    networks: ['1', '10', '56', '80001', '250', '42161', '43114'],
  },
  '0x94dd9c6152a2a0bbcb52d3297b723a6f01d5f9f7': {
    name: 'Balancer Fee Collector',
    logo: balancer,
    id: '0x94dd9c6152a2a0bbcb52d3297b723a6f01d5f9f7',
    networks: ['1', '10', '80001', '100', '42161'],
  },

}
