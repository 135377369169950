import React from 'react'
import styled from 'styled-components'
import { useLocation, useParams } from 'react-router-dom'
import { SmallText } from '../styles/texts'
import dividerImg from '../assets/breadcrumb-divider.svg'
import { parseActionName } from '../utils/general-utils'
import useNamespace from '../hooks/useNamespace'

const Breadcrumbs = () => {
  const location = useLocation()
  const params = useParams()
  const namespace = useNamespace(params.id)
  const pathnames = location.pathname.split('/').filter(x => x)

  return (
    <BreadcrumbsSection>
      {pathnames.map((name, index) => {
        if (name === params.id) {
          return (
            <span key={index}>
              <SmallText>{namespace?.data && parseActionName(namespace.data)}</SmallText>
            </span>
          )
        } else {
          return (
            <span key={index}>
              <Divider src={dividerImg} alt=">" />
              <SmallText>{name && parseActionName(name)}</SmallText>
            </span>
          )
        }
      })}
    </BreadcrumbsSection>
  )
}

const BreadcrumbsSection = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 700px) {
    flex-wrap: wrap;
  }
  span {
    display: flex;
    align-items: center;
  }
  p {
    color: #a7acc1;
  }
`

const Divider = styled.img`
  padding: 0 4px;
`

export default Breadcrumbs
