import thegraph from '../assets/environments/The-Graph.svg'
import oneinch from '../assets/environments/1Inch.svg'
import balancer from '../assets/environments/Balancer.svg'
import paraswap from '../assets/environments/paraswap.svg'
import beet from '../assets/environments/beet.svg'
import exodus from '../assets/environments/Exodus.svg'
import rainbow from '../assets/environments/Rainbow.svg'
import trezor from '../assets/environments/trezor.svg'

export const EnvironmentsDictionary = {
  '0x93e4eb250117a846013a0724340b72f4313d5208742dd7ac55599caacb9421b4': {
    name: 'The Graph Refunder',
    logo: thegraph,
  },
  '0xfdd726b115adcf00c983b7a7eda7b64d763d0941d8d1e1bfe5160db2352bf9c7': {
    name: '1inch Tw Airdropper',
    logo: oneinch,
  },
  '0x7ec0b23feb8cdf2076cff09548ea89e03df00781726f9f5e1c186ff59d87a727': {
    logo: oneinch,
  },
  '0xcb62e34ae5c64e59518dd829381bf6d2afb5d52e4ca9c50c5b0d1cd30c98ea9f' : {
    logo: oneinch,
  },
  '0xd28bd4e036df02abce84bc34ede2a63abcefa0567ff2d923f01c24633262c7f8' : {
    logo: balancer,
  },
  '0x9f2ad2c9daab651cb3e704ff3cbbfd31629331b8cf06385e1f0a249e9704d42b' : {
    logo: paraswap,
    name: 'ParaSwap Fee Collector V3'
  },
  '0xd0072def1ccb1482a6d0f573d5df43c973811cbdbb1e352243ebe0be83c76171' : {
    logo: paraswap,
    name: 'ParaSwap Fee Collector'
  },
  '0xcbb8cbc108ced6d2b1038d7694f5279f48b88496a72137fe36523f9d42b42787' : {
    logo: beet,
    defaultChainId: 250
  },
  '0x769d073d4d4ba34283aab39d9c88461f35fddf912d64ad62528efffeb6707c38': {
    logo: exodus,
  },
  '0x9dbf0fa9ad2f59a8355b9e49e8d4dfff229cafe3bfe82ae5e16674c5accbe8dd': {
    logo: exodus,
  },
  '0x97da7fdc3bcec9c666aeb1c6831cc19879fc23d4a6712d8803f6e6f3f03306d6': {
    logo: rainbow,
  },
  '0xf6aae770acb7f27560cc123f2bc86482bfeaf778c401990773b94c2c3e336b87': {
    logo: trezor,
  },
}
