import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { getEtherscanLink, shortenHash } from '../utils/web3-utils'
import { parseActionName } from '../utils/general-utils'
import openImg from '../assets/panel/open.svg'
import { Heading1, LargeText, StandardText } from '../styles/texts'
import Status from '../components/Status'
import Address from '../components/Address'
import useTaskData from '../hooks/useTaskData'
import LongId from '../components/LongId'
import useTaskMetadata from '../hooks/useTaskMetadata'

const ActionDetail = ({
  open,
  primitives,
  children,
  right,
  onClose,
  item,
}) => {
  const { data: dataMeta } = useTaskMetadata(item?.id)
  console.log(item)
  return (
    <Detail isopen={open.toString()}>
      <div className="overlay" onClick={onClose} />
      <div
        className="wrap"
        onClick={e => e.target.tagName.toLowerCase() === 'a' && onClose()}
      >
        <Heading1 style={{ marginTop: '15px' }}>Task details</Heading1>
        <DetailItem>
          <StandardText className="label">Task</StandardText>
          <StandardText>{parseActionName(item?.task?.name)}</StandardText>
        </DetailItem>
        <DetailItem>
          <StandardText className="label">Date</StandardText>
          <StandardText>
            {moment.unix(item?.executedAt).format('MMM Do HH:mm:ss')}
          </StandardText>
        </DetailItem>
        <DetailItem>
          <StandardText className="label">ID</StandardText>
          <StandardText>
            {item?.id}
          </StandardText>
        </DetailItem>
        <DetailItem>
          <StandardText className="label">Plan ID</StandardText>
          <StandardText>
            {item?.planId}
          </StandardText>
        </DetailItem>
        <DetailItem>
          <StandardText className="label">Task address</StandardText>
          <Address address={item?.task?.address} chainId={item?.chainId} short />
        </DetailItem>
        <DetailItem>
          <StandardText className="label">ChainId</StandardText>
          {item?.chainId}
        </DetailItem>
        <DetailItem>
          <StandardText className="label">Status</StandardText>
          <StandardText>
            <Status
              status={item?.status?.type}
              width={760}
            />
          </StandardText>
        </DetailItem>
        <StandardText className="label">Token In</StandardText>

        <StandardText>
          <div><LongId longId={item?.in?.token} /></div>
          <div>Amount: {item?.in?.amount}</div>
          <div>Price: ${item?.in?.amountUsd}</div>
        </StandardText>
        {
          item?.out?.map(t => {
            return (
              <>              <StandardText className="label">Token Out</StandardText>
                <StandardText>
                </StandardText>
                <StandardText>
                  <LongId longId={t.token} />
                  <div>Amount: {t.amount}</div>
                  <div>Price: ${t.amountUsd}</div>
                </StandardText>
              </>

            )
          })
        }

        <StandardText className="label">Execution type</StandardText>

        <StandardText>

          <LongId longId={item?.task?.executionType} />

        </StandardText>
       
        <StandardText className="label">Smart Vault</StandardText>
        <StandardText>
          <Address address={item?.smartVault} chainId={item?.chainId} short showIdentity={false} />
        </StandardText>
        {item?.status?.type === ('success' || 'reverted') && <RenderTransactionInfo item={item} isOpen={open} />}

        <br />
        <StandardText className="label">CallData</StandardText>

<StandardText>

  <LongId longId={item?.calldata} />

</StandardText>
<br />

        <StandardText className="label">Metadata</StandardText>
        <StandardText>
          <Flex>
         { JSON.stringify(dataMeta)}
          </Flex>
        </StandardText>
        
      </div>
    </Detail>
  )
}

const RenderTransactionInfo = ({ item }) => {
  const { data } = useTaskData(item?.id)

  return (
    <>
      <br />

      <LargeText>Transaction Info</LargeText>
      <StandardText className="label">Transaction Sender</StandardText>
      <StandardText>

        <Address address={data?.sender} chainId={data?.chainId} short />
      </StandardText>


      <StandardText className="label">Transaction Hash</StandardText>
      <StandardText>
        <Link
          href={getEtherscanLink(
            data?.chainId,
            data?.hash,
            'transaction'
          )}
          target="_blank"
          rel="noreferrer"
        >
          <StandardText color="#A996FF">{shortenHash(data?.hash)}</StandardText>
          <OpenLink alt="" src={openImg} />
        </Link>

      </StandardText>

    </>

  )
}

const Link = styled.a`
  display: flex;
  align-items: center;
`

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  white-space: break-spaces;
`

const DetailItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  margin-bottom: 40px;
  p {
    margin: 0;
    word-break: break-word;
    display: flex;
    align-items: center;
  }
  .relative {
    position: relative;
  }
`

const Detail = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 100;
  word-break: break-word;
  .label {
    color: ${props => props.theme.textGrey};
  }

  .overlay {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    opacity: 0;
    will-change: opacity;
    pointer-events: none;
    transition: opacity 0.3s cubic-bezier(0, 0, 0.3, 1);
  }

  ${props =>
    props.isopen === 'true' && '.overlay { opacity: 1; pointer-events: auto;}'};

  .wrap {
    position: fixed;
    box-sizing: border-box;
    height: 100%;
    width: 504px;
    @media only screen and (max-width: 510px) {
      width: 100%;
    }
    padding: 50px;
    background: ${props => props.theme.panel};
    overflow-y: auto;
    overflow-x: hidden;
    transform: translateX(-100%);
    will-change: transform;
    z-index: 101;
    pointer-events: auto;
    transition: transform 130ms ease-out;
    right: 0;
    transform: translateX(100%);
  }

  ${props =>
    props.isopen === 'true' &&
    '.wrap { transform: none; pointer-events: auto; transition: transform 330ms ease-in;}'};

  h2 {
    text-align: left !important;
  }
  .token {
    width: 26px;
    padding-left: 7px;
  }
`

const OpenLink = styled.img`
  height: 20px;
  padding-left: 7px;
`

export default ActionDetail
