import styled from 'styled-components'

export const DisplayHeading = styled.h1`
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'VisbyBold';
  font-size: 56px;
  font-style: normal;
  font-weight: 500;
  line-height: 68px;
  letter-spacing: 1px;

  @media only screen and (min-width: 768px) and (max-width: 1200px) {
    font-size: 44px;
    line-height: 56px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 44px;
  }
`

export const Heading1 = styled.h1`
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'VisbyBold';
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px;
  letter-spacing: 1px;

  @media only screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 32px;
  }
`

export const Heading2 = styled(Heading1)`
  font-size: 32px;
  line-height: 44px;
  @media only screen and (max-width: 1200px) {
    font-size: 20px;
    line-height: 32px;
  }
`

export const Heading3 = styled(Heading1)`
  font-size: 20px;
  line-height: 32px;
`

export const LargeText = styled.p`
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'DMSans';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 1px;
`
export const StandardText = styled(LargeText)`
  font-size: 16px;
  line-height: 22px;
`

export const SmallText = styled(LargeText)`
  font-size: 14px;
  line-height: 20px;
`

export const Container = styled.div`
  margin: auto;
  max-width: 1140px;
  margin: auto;

  @media only screen and (min-width: 701px) and (max-width: 1200px) {
    max-width: 90%;
  }
  @media only screen and (min-width: 1900px) {
    margin: auto;
  }
  @media only screen and (max-width: 700px) {
    margin: 0 24px;
    padding 24px 0;
  }

`
